import { SelectInput, required, Edit, ReferenceInput, SimpleForm, TextInput, TextField } from 'react-admin';

export const PersonaEdit = () => (
	<Edit>
		<SimpleForm>
			<TextInput source="name" />
			<TextInput source="description" fullWidth />
			<TextInput source="knowledge" multiline fullWidth rows="4" />
			<TextInput source="characteristics" multiline fullWidth rows="4" />
			{/*
			<ReferenceInput source="voice_id" reference="voices" disabled={true} defaultValue={1}>
				<SelectInput optionText="name" validate={required()} fullWidth={false} />
			</ReferenceInput>
			*/}
		</SimpleForm>
	</Edit>
);

export default PersonaEdit;

//import logo from './logo.svg';
import './App.css';

import { Admin, Resource } from 'react-admin';
//import { Route } from 'react-router-dom';

import dataProvider from './Providers/dataProvider';
import authProvider from './Providers/authProvider';

//import { Dashboard, Settings } from './Pages';
//import { AppLayout } from './Layout';
import { PersonaEdit, PersonaList, PersonaCreate } from './Resources/Persona';
import { EventEdit, EventList, EventCreate } from './Resources/Event';
//import { VoiceEdit, VoiceList, VoiceCreate } from './Resources/Voice';
import { UserEdit, UserList, UserCreate } from './Resources/User';
//import { SettingCreate, SettingList, SettingEdit } from './Resources/Setting';
import { FormEntryCreate, FormEntryEdit, FormEntryList } from './Resources/FormEntry';


function App() {
	return (
		<Admin
			dataProvider={dataProvider}
			authProvider={authProvider}
			disableTelemetry
			requireAuth
			>

			<Resource name="events" list={EventList} edit={EventEdit} create={EventCreate} />
			<Resource name="form-entries" list={FormEntryList} edit={FormEntryEdit} create={FormEntryCreate} options={{label:"Participants" }} />
			<Resource name="personas" list={PersonaList} edit={PersonaEdit} create={PersonaCreate} />
			{/*
			<Resource name="voices" list={VoiceList} edit={VoiceEdit} create={VoiceCreate} />
			*/}
			<Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
			{ /*
			<Resource name="settings" list={SettingList} edit={SettingEdit} create={SettingCreate} />
			*/}
		</Admin>
	);
}

export default App;

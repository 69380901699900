import React from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	required
} from 'react-admin';

const PersonaCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="name" />
			<TextInput source="description" fullWidth />
			<TextInput source="knowledge" multiline fullWidth rows="4" />
			<TextInput source="characteristics" multiline fullWidth rows="4" />
		</SimpleForm>
	</Create>
);

export default PersonaCreate;

//import simpleRestProvider from 'ra-data-simple-rest';
import jsonServerProvider from 'ra-data-json-server';

// For dev purposes only.
//export const dataProvider = jsonServerProvider('http://localhost:4000'); 
export const dataProvider = jsonServerProvider('/api'); 


//const dataProvider = simpleRestProvider( 'https://dev.vm/' );



export default dataProvider;
import { TextField, DateInput, Edit, ReferenceInput, TabbedForm, TextInput, SelectInput, required, FileInput, ReferenceManyField, Datagrid, EditButton } from 'react-admin';
import { Typography } from '@mui/material';
import { StatusChoices } from './meta';

const cardStyle = { width: 1, margin: 1 };

export const EventEdit = () => (
	<Edit>
		<TabbedForm>
			<TabbedForm.Tab label="Details">
				<TextInput source="name" validate={required()} />
				<DateInput source="start_date" fullWidth={false} />
				<ReferenceInput source="persona_id" reference="personas">
					<SelectInput optionText="name" validate={required()} fullWidth={false} />
				</ReferenceInput>
				<SelectInput source="status" choices={StatusChoices} validate={required()} fullWidth={false} />
				<TextInput source="pre_experience_messaging" multiline rows="4" />
				<FileInput source="pre_experience_audio" fullWidth={false} sx={{marginBottom: 2}} >
					<TextField source="title" />
				</FileInput>

				<Typography>Topic 1</Typography>
				<TextInput source="topic1_title" label="Title" validate={required()} />
				<TextInput source="topic1_description" label="Description" multiline validate={required()} />

				<Typography>Topic 2</Typography>
				<TextInput source="topic2_title" label="Title" />
				<TextInput source="topic2_description" label="Description" multiline />

				<Typography>Topic 3</Typography>
				<TextInput source="topic3_title" label="Title" />
				<TextInput source="topic3_description" label="Description" multiline />

			</TabbedForm.Tab>
			<TabbedForm.Tab label="Participants">
				<ReferenceManyField reference="form-entries" target="event_id">
					<Datagrid rowClick="edit" bulkActionButtons={false} >
						<TextField source="name" />
						<TextField source="title" />
						<TextField source="company" />
						<TextField source="industry" />
						<TextField source="location" />
						<EditButton />
					</Datagrid>
				</ReferenceManyField>
			</TabbedForm.Tab>
		</TabbedForm>
	</Edit>
);

export default EventEdit;
import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, ReferenceInput, AutocompleteInput, required } from 'react-admin';

const FormEntryEdit = (props) => (

	<Edit>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<TextInput source="company" validate={required()} />
			<TextInput source="title" validate={required()} />
			<TextInput source="industry" validate={required()} />
			<TextInput source="location" validate={required()} />
			<TextInput source="email" validate={required()} />
			<ReferenceInput source="event_id" reference="events" >
				<AutocompleteInput optionText="name" validate={required()} fullWidth={false} disabled/>
			</ReferenceInput>

		</SimpleForm>
	</Edit>
);

export default FormEntryEdit;

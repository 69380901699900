import {
	Datagrid,
	DateField,
	List,
	ReferenceField,
	TextField,
	TopToolbar,
	CreateButton

} from 'react-admin';

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);
  
export const EventList = (props) => (
    <List {...props} actions=<ListActions />>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <DateField source="start_date" />
            <ReferenceField source="persona_id" reference="personas" sortBy="persona.name">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
        </Datagrid>
    </List>
);

export default EventList;
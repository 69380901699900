import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  TextInput,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
} from 'react-admin';

const PersonaFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <TextInput source="name" label="Name" />,
];

const PersonaListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const PersonaList = (props) => (
  <List
    {...props}
    actions={<PersonaListActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <EditButton />
    </Datagrid>
  </List>
);

export default PersonaList;

import React from 'react';
import { Create, SimpleForm, TextInput, DateInput, ReferenceInput, SelectInput, required, AutocompleteInput } from 'react-admin';

const FormEntryCreate = (props) => (

	<Create>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<TextInput source="company" validate={required()} />
			<TextInput source="title" validate={required()} />
			<TextInput source="industry" validate={required()} />
			<TextInput source="location" validate={required()} />
			<TextInput source="email" validate={required()} />
			<ReferenceInput source="event_id" reference="events">
				<AutocompleteInput optionText="name" validate={required()} fullWidth={false} />
			</ReferenceInput>

		</SimpleForm>
	</Create>
);

export default FormEntryCreate;

import { Create, PasswordInput, SelectArrayInput, SimpleForm, TextInput, required } from 'react-admin';

import { RoleChoices } from './meta';

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="email" validate={required()} />
            <PasswordInput source="password" validate={required()} />
            <TextInput source="name" validate={required()} />
            <SelectArrayInput source="role" choices={RoleChoices} fullWidth={false} />
        </SimpleForm>
    </Create>
);

export default UserCreate;